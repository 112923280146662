// Tabview
.p-tabview .p-tabview-nav {
    background: none;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: none;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: none;
}

.p-tabview .p-tabview-panels {
    background: none;
    padding: 1rem 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: none;
}

// Card
.p-card {
    border-radius: 8px;
    height: 100%;
    box-shadow: none;
}

.p-card-content  .container {
    padding-left: 0;
    padding-right: 0;
}

